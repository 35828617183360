import React from "react";

const sendEmail = () => {
  const email = "info@marokkobiz.com";
  const subject = "Msg from marokkoBiz";
  const body = "Body of the email";
  window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
};


const Contact = () => {
  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div className="contact-form-container">
        <input type="text" placeholder="youmail@yourmail.com" />
        <button className="secondary-button" onClick={sendEmail}>Submit</button>
      </div>
    </div>
  );
};



export default Contact;
