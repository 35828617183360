import React from "react";
import Logo from "../Assets/Logo.png";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
        
          <a href="https://web.facebook.com/profile.php?id=61559592603937" target="_blank"><FaFacebookF /></a> 
</div>
      </div>



      <div className="footer-section-two">
  <div className="footer-section-columns">
    <span>
      Marokko Biz CoWorking<br />
      Lot. Al Maghreb, Al Arabi D, lot No 76<br />
      14000 Kenitra, Morocco<br />
      Phone office: +212 530286836<br />
      Whatsapp: +212 (0) 779537401
    </span>
  </div>
</div>



      <div className="footer-section-two">


        <div className="footer-section-columns">
          <br/> <br/>
          <br/>  <br/>
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>


    </div>
  );
};

export default Footer;
