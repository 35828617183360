import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image2.png";
import aboutimg from "../Assets/backabout4.png";
import { BsFillPlayCircleFill } from "react-icons/bs";


const About = () => {
  return (
    <div id="about" className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt=""  className="background-image"   />
      </div>
      <div className="about-section-image-container">
        <img src={aboutimg} alt=""  className="about-image"/>
      </div>
      <div className="about-section-text-container">
        
        <h1 className="primary-heading">
        <pre>Marokko Biz CoWorking<br/>
            </pre>
            <pre>Empowering Your Success..</pre>
        </h1>
        <p className="primary-text">
        
        Welcome to Marokko Biz CoWorking.<br/><br/> 
        Marokko Biz CoWorking is dedicated to providing flexible and affordable business spaces and professional services. <br/><br/> 
        
        Whether you're an independent professional, a startup, or a small business, we have the right solution to meet your needs.<br/><br/> 

        Our goal is to support your business growth and ensure you can work efficiently from anywhere.
        </p>
        <p className="primary-text">
        Let us help you achieve your business goals.
        </p>
        <div className="about-buttons-container">
          <a href="#services">
          <button className="secondary-button">Explore Our Services</button>
         </a>
        </div>
      </div>
    </div>
  );
};

export default About;
