import React,  { useState } from "react";
import loca from "../Assets/loca.jpg";
import timer from "../Assets/timer.png";
import servicemsg from "../Assets/service-msgs.jpg";
import servicefix from "../Assets/service-msgs-ffff.jpg"
import serviceDelivry from "../Assets/liv2.jpg"
import serviceWork from "../Assets/serviceworkspace.jpg"
import servicemail from "../Assets/servicemail3.jpg"





  const Work = () => {
    const priceList = [

      {
        image:servicemsg,
        title: "Phone/message service",
        price: "100.00 MAD",
        terms: "3 Months",
        specialConditions: "Requires you to have a business address agreement ",
        prepayment: ""
      },

      {
        image:servicefix,
        title: "Phone service with fixed phone number",
        price: "250.00 MAD",
        terms: "6 Months",
        specialConditions: "Requires you to have a business address agreement ",
        prepayment: ""
      },
      {
        image:serviceDelivry,
        title: "Delivery service",
        price: "50.00 MAD",
        terms: "3 Months",
        specialConditions: "Requires you to have a business address agreement",
        prepayment: "Shipping fee must be prepaid"
      },
       
      {
        image:servicemail,
        title: "Post forwarding",
        price: "50.00 MAD",
        terms: "6 Months",
        specialConditions: "Requires you to have a business address agreement",
        prepayment: ""
      },

   
 


      {
        image:loca,
        title: "Official business address",
        price: "100.00 MAD",
        terms: "12 Months",
        specialConditions: "",
        prepayment: ""
      }
    ];
  
    const [isPopupVisible, setIsPopupVisible] = useState(false);
  
    const handleFirstLinkClick = (e) => {
      e.preventDefault();
      setIsPopupVisible(true);
    };
  
    const closePopup = () => {
      setIsPopupVisible(false);
    };
  
    return (
      <div className="work-section-wrapper">
        <div  id="services" className="work-section-top">
      
          <pre class="custom-heading"> Unlock the Potential of Our Service Offerings </pre>
        </div>
  
        <div className="work-section-bottom">
          {priceList.map((item, index) => (
            <div className="work-section-info" key={index}>
              <img src={item.image} alt="" />
              <h2>{item.title}</h2>
              <p>Price per month: {item.price}</p>
              <p>Terms: {item.terms}</p>
              <p>Special conditions: {item.specialConditions}</p>
              <p>Prepayment: {item.prepayment}</p>
            </div>
          ))}
        </div>
  
        {isPopupVisible && (
          <div className="popup-overlay">
            <div className="popup-content">
              <span className="close-btn" onClick={closePopup}>&times;</span>
              <img src={timer} alt="Coming Soon" className="timer" />
              <h1>It's coming soon</h1>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default Work;